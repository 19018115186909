import React, { ReactNode, FC } from "react";
import { GradientBottom, GradientTop } from "../Gradient/index.js";
import styled, { css, ThemeProvider } from "styled-components";
import { white, darkBlue, lightBlue } from "site-ui/colors";
import { Box, Container } from "@material-ui/core";
import theme from "styled-theming";

const color = theme("mode", {
  default: white,
  lightBlue: darkBlue,
  dark: white,
  darkBlue: white,
  light: darkBlue,
});
export interface HeroLandingProps {
  bgImage?: ReactNode;
  bgColor?: string;
  color?: string;
  isCornerGradientsShown?: boolean;
  isGradientOverlayShown?: boolean;
  align?: "left" | "center";
  triangleGradientStartColor?: string;
  triangleGradientEndColor?: string;
  overlayGradientStartColor?: string;
  overlayGradientEndColor?: string;
  logos?: ReactNode | ReactNode[];
  compact?: boolean;
  bgAlign?: "cover" | "left" | "right";
  gradientPoint?: number;
  theme?: string;
  narrow?: boolean;
  minHeight?: number;
}

const defaultProps: HeroLandingProps = {
  align: "left",
  isCornerGradientsShown: true,
  isGradientOverlayShown: true,
  bgColor: lightBlue,
  triangleGradientStartColor: lightBlue,
  triangleGradientEndColor: lightBlue,
  overlayGradientStartColor: lightBlue,
  overlayGradientEndColor: lightBlue,
  compact: false,
  narrow: false,
  bgAlign: "cover",
  gradientPoint: 25,
  theme: "dark",
  color: darkBlue,
};

export const HeroLanding: FC<HeroLandingProps> = ({
  bgImage,
  children,
  align,
  isCornerGradientsShown,
  isGradientOverlayShown,
  bgColor,
  triangleGradientStartColor,
  triangleGradientEndColor,
  overlayGradientStartColor,
  overlayGradientEndColor,
  logos,
  compact,
  narrow,
  bgAlign,
  gradientPoint,
  theme,
  minHeight,
  color,
}) => {
  return (
    <ThemeProvider theme={{ mode: theme || "dark" }}>
      <Wrapper
        bgColor={bgColor}
        color={color}
        compact={compact}
        align={align}
        narrow={narrow}
      >
        {bgImage && (
          <StyledStyledBackground align={bgAlign}>
            {bgImage}
          </StyledStyledBackground>
        )}
        {isGradientOverlayShown && !!bgImage && (
          <GradientOverlay
            start={overlayGradientStartColor}
            point={gradientPoint}
            end={overlayGradientEndColor}
          ></GradientOverlay>
        )}
        {isCornerGradientsShown && [
          <GradientBottom
            style={{ zIndex: 1 }}
            start={triangleGradientStartColor}
            end={triangleGradientEndColor}
          />,
          <GradientTop
            start={triangleGradientStartColor}
            end={triangleGradientEndColor}
          />,
        ]}
        <Container maxWidth={!narrow ? "lg" : "md"}>
          <Box minHeight={minHeight} display="flex" justifyContent={align}>
            <Content>{children}</Content>
          </Box>
        </Container>
      </Wrapper>
      <Container>
        <>
          {logos && (
            <LogosContainer>
              {logos.map((logo, i) => (
                <div key={i}>{logo}</div>
              ))}
            </LogosContainer>
          )}
        </>
      </Container>
    </ThemeProvider>
  );
};

HeroLanding.defaultProps = defaultProps;

const LogosContainer = styled.div`
  display: flex;
  margin: 20px auto;
  background-color: ${white};
  align-items: center;
  align-content: space-between;
  justify-content: space-between;
  flex-wrap: wrap;
  text-align: center;
  & > * {
    padding: 10px 20px;
    width: 50%;
  }
  @media (min-width: 640px) {
    height: 50px;
    flex-direction: row;
    & > * {
      width: 25%;
      max-width: 200px;
    }
  }
  img {
    filter: grayscale(1) contrast(0.4) brightness(1.4);
  }
`;

const GradientOverlay = styled.div<{
  start: string;
  end: string;
  point: number;
}>`
  background-image: linear-gradient(
    90deg,
    ${(props) => props.start} ${(props) => props.point}%,
    ${(props) => props.end} 72%
  );
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const StyledStyledBackground = styled.div<{ align: string }>`
  position: absolute;
  ${({ align }) =>
    align !== "cover" &&
    css`
      text-align: ${align};
    `}
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  & > img {
    object-fit: cover !important;
    object-position: 80% center !important;
    width: 100% !important;
    height: 100%;
  }
`;

const Wrapper = styled.section<HeroLandingProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  @media (min-width: 480px) {
    align-items: ${(props) => props.align};
    text-align: ${(props) => props.align};
  }
  background-color: ${(props) => props.bgColor};
  ${(props) => !props.compact && `padding: 80px 0px 20px;`}
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${color};
  }
  p,
  li,
  a,
  ul {
    color: ${color};
  }
  a {
    border-bottom-color: ${color};
  }

  img {
    width: 100%;
    display: block;
  }
  .gatsby-image-wrapper {
    min-height: 100%;
    height: 100%;
  }
`;

const Content = styled.div`
  margin: 20px 0;
  max-width: 1000px;
  position: relative;
  color: ${darkBlue};
  z-index: 2;
  & > *:not(:last-child) {
    margin-bottom: 24px;
  }
  @media (min-width: 960px) {
    margin: 6vw 0 4vw;
  }
`;
