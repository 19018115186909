import { Grid, Typography, Box } from "@material-ui/core";
import React, { FC, ReactNode } from "react";
import { Section } from "site-ui/src/components/Section";
import { GatsbyImage } from "gatsby-plugin-image";
import { ChecklistCheck } from "site-ui/src/components/ChecklistCheck";
import { renderRichText } from "twill-contentful-text-render";
import { useTranslation } from "twill-net-localization";
import { Link } from "twill-net-localization";
import {
  ContentfulRichTextGatsbyReference,
  RenderRichTextData,
} from "gatsby-source-contentful/rich-text";

type Feature = {
  title: string;
  description?: ReactNode;
};

type Service = {
  excludeFromServicesOverview: boolean;
  title: string;
  description: ReactNode;
  mainFeatures: Feature[];
  heroImage?: any;
  reverse?: boolean;
  seoMeta: { slug: string };
  group: any;
};

interface ServiceProps {
  title: string;
  headline: ReactNode;
  services: Service[];
  isGrey?: boolean;
  anchor?: number;
  shortDescription?: RenderRichTextData<ContentfulRichTextGatsbyReference>;
}

function ServiceComponent({
  title,
  description,
  mainFeatures,
  heroImage,
  reverse,
  seoMeta,
  group,
}: Service) {
  const [t] = useTranslation();
  return (
    <>
      <Grid
        container
        spacing={4}
        direction={reverse ? "row-reverse" : "row"}
        alignItems={"center"}
        justify="space-between"
      >
        <Grid item xs={12} md={6}>
          {heroImage && (
            <GatsbyImage
              alt={heroImage.description}
              image={heroImage.localFile.childImageSharp.gatsbyImageData}
              style={{
                borderRadius: 4,
                boxShadow: "0 29px 42px 0 rgba(0, 0, 0, 0.33)",
                marginBottom: "64px",
              }}
            />
          )}
        </Grid>
        <Grid item xs={12} md={5}>
          <Box maxWidth={640}>
            {title && <Typography variant="h3">{title}</Typography>}
            {description && (
              <Typography variant="body1" gutterBottom component="div">
                {renderRichText(description)}
              </Typography>
            )}
            <ul style={{ paddingLeft: 0, marginTop: "16px" }}>
              {mainFeatures &&
                mainFeatures.map((feature) => (
                  <ChecklistCheck>
                    <Box pb={1}>{feature.title}</Box>
                  </ChecklistCheck>
                ))}
            </ul>
            <Box fontWeight="bold">
              {seoMeta && group.servicespageassembly && (
                <Link
                  to={`/${group.servicespageassembly[0].page[0].seoMeta.slug}/${seoMeta.slug}`}
                >
                  {t("common.readMore", "Read more")} &rarr;
                </Link>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export const ServiceSection: FC<ServiceProps> = ({
  title,
  headline,
  services,
  isGrey,
  anchor,
  shortDescription,
}) => {
  return (
    <Section variant={isGrey ? "darker" : "default"}>
      <Box
        mx="auto"
        textAlign={{ md: "center" }}
        mb={{ xs: 0, sm: 2, md: 6 }}
        maxWidth={640}
      >
        <Typography variant="h5">
          <a id={`section-${anchor}`} /> {title}
        </Typography>
        <Typography variant="h2" gutterBottom>
          {headline}
        </Typography>
      </Box>
      <Box
        mx="auto"
        textAlign={{ md: "center" }}
        mb={{ xs: 0, sm: 2, md: 6 }}
        maxWidth={640}
      >
        {shortDescription && renderRichText(shortDescription)}
      </Box>
      {services &&
        services
          .filter((service) => !service.excludeFromServicesOverview)
          .map((service, key) => (
            <ServiceComponent {...service} reverse={key % 2 === 0} />
          ))}
    </Section>
  );
};
