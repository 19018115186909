import React, { ReactElement } from "react";
import { Typography, Box } from "@material-ui/core";
import { HeroLanding } from "site-ui/src/components/HeroLanding";
import { midBlue } from "site-ui/colors";
import { renderRichText } from "twill-contentful-text-render";
import { CategorySelector } from "../CategorySelector/CategorySelector";

export interface IMainHeaderProps {
  title: string;
  description: string;
  categories: any[];
}

export function MainHeader(props: IMainHeaderProps): ReactElement {
  return (
    <HeroLanding
      align="center"
      overlayGradientEndColor="rgba(20, 33, 70, 1)"
      overlayGradientStartColor={midBlue}
      triangleGradientEndColor="rgba(38, 101, 147, 0.44)"
      triangleGradientStartColor="rgba(80, 175, 200, 0.51)"
      bgColor={midBlue}
    >
      <Box maxWidth={640}>
        <Typography variant="h1">{props.title}</Typography>
        <Typography variant="subtitle1">
          {renderRichText(props.description)}
        </Typography>
      </Box>
      <CategorySelector categories={props.categories} />
    </HeroLanding>
  );
}
