import React, { ReactElement } from "react";
import { Typography, Box } from "@material-ui/core";
import { useTranslation } from "twill-net-localization";
import styled from "styled-components";

export interface ICategorySelectorProps {
  categories: any[];
}

export function CategorySelector({
  categories,
}: ICategorySelectorProps): ReactElement {
  const { t } = useTranslation();

  return (
    <Box textAlign="center" mt={10}>
      <Typography variant="h4">
        <strong>{t("common.ourServices", "Our services")}</strong>
      </Typography>
      <Box display="flex" mx="auto" mt={2} justifyContent="center">
        {categories.map((category, key) => (
          <CategorySelectorLink href={`#section-${key}`}>
            <Box mx={1}>{category.title}</Box>
          </CategorySelectorLink>
        ))}
      </Box>
    </Box>
  );
}

const CategorySelectorLink = styled.a`
  text-decoration: underline;
  font-weight: 200;
`;
