import React from "react";
import { graphql } from "gatsby";
import { useTranslation } from "twill-net-localization";
import { MainHeader } from "../../ui/components/MainHeader/MainHeader";
import { ServiceSection } from "../../ui/components/ServiceSection";
import { Section } from "site-ui/src/components/Section";
import Box from "@material-ui/core/Box";
import { options, renderRichText } from "twill-contentful-text-render";

export default ({ data }) => {
  const {
    mainPage: { categories, description, title, introText },
  } = data;
  const { t } = useTranslation();
  return (
    <>
      <MainHeader
        title={title}
        description={description}
        categories={categories}
      />
      {introText && (
        <Section pb={0}>
          <Box maxWidth={640} textAlign="center" mx="auto">
            {renderRichText(introText, options)}
          </Box>
        </Section>
      )}
      {categories.map((category, key) => (
        <ServiceSection
          {...category}
          services={
            category.services?.length > 0
              ? category.services
              : category.relatedServices
          }
          isGrey={key % 2 !== 0}
          anchor={key}
        ></ServiceSection>
      ))}
    </>
  );
};

export const pageQuery = graphql`
  fragment ServiceOverviewFields on ContentfulService {
    title
    excludeFromServicesOverview
    excludeFromMenu
    description: shortDescription {
      raw
    }
    seoMeta {
      slug
    }
    group {
      servicespageassembly {
        page {
          seoMeta {
            slug
          }
        }
      }
    }
    heroImage {
      localFile {
        childImageSharp {
          gatsbyImageData(
            width: 573
            height: 342
            placeholder: BLURRED
            layout: CONSTRAINED
          )
        }
      }
      description
    }
    mainFeatures {
      title
    }
  }
  query ServicesMainPageQuery(
    $contentful_id: String!
    $locale: String!
    $language: String!
  ) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    mainPage: contentfulServicesPageAssembly(
      contentful_id: { eq: $contentful_id }
      node_locale: { eq: $locale }
    ) {
      node_locale
      title
      description {
        raw
      }
      introText {
        raw
      }
      categories {
        title
        headline
        shortDescription {
          raw
        }
        # This grabs relation from Service -> Category, so we can build list automatically, if not specified otherwise
        relatedServices: service {
          ...ServiceOverviewFields
        }
        # This grabs relation from Category -> Service, if we need to specify exact order in which services must go
        services {
          ...ServiceOverviewFields
        }
      }
    }
  }
`;
